import { ChangeEvent, KeyboardEvent, ReactElement, useEffect, useRef, useState, MouseEvent } from 'react'
import { Send, X } from 'lucide-react'

import { TicketFile } from 'lib/api/ticket-files/ticket-files'
import Textarea from 'lib/components/textarea/textarea'
import IconButton from 'lib/components/buttons/icon-button'

import { useAnnotationsContext } from '../providers/annotations-provider'
import { useRequestContext } from 'components/pages/request/providers/request-provider'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import WYSIWYGTextarea from 'lib/components/wysiwyg/wysiwyg-textarea'

interface ImageAnnotationFormProps {
  ticketFile: TicketFile
}

export default function ImageAnnotationForm({ ticketFile }: ImageAnnotationFormProps): ReactElement {
  const textAreaRef = useRef(null)
  const [showError, setShowError] = useState(false)
  const [currentEditAnnotationBodyText, setCurrentEditAnnotationBodyText] = useState('')
  const { showValidationErrors } = useRequestContext()
  const { addAnnotation, areAnnotationsValid, cancelAnnotation } = useAnnotationsContext()
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()

  function onSendClick(event: MouseEvent) {
    event.stopPropagation()
    submit()
  }

  function onCancelClick(event: MouseEvent) {
    event.stopPropagation()

    cancelAnnotation()
  }

  function submit() {
    if (currentEditAnnotationBodyText.trim().length <= 3) {
      setShowError(true)
      return
    }

    addAnnotation(ticketFile, currentEditAnnotationBodyText)
  }

  function onTextAreaChange(event: ChangeEvent<HTMLTextAreaElement>) {
    const newValue = event.target.value
    onChange(newValue)
  }

  function onChange(newValue: string) {
    setCurrentEditAnnotationBodyText(newValue)
    setShowError(false)
  }

  useEffect(() => {
    // This is a hack to get the textarea to focus when the annotation is created
    // and the annotation pane is not opened yet
    const timeout = setTimeout(() => {
      if (textAreaRef.current) {
        if (isFeatureFlagEnabled('request_wysiwyg')) {
          textAreaRef.current.editor.commands.focus()
        } else {
          textAreaRef.current.focus()
        }
      }
    }, 100)

    return () => clearTimeout(timeout)
  }, [isFeatureFlagEnabled])

  async function onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      submit()
    } else if (e.key === 'Escape') {
      cancelAnnotation()
    }
  }

  useEffect(() => {
    if (showValidationErrors && !areAnnotationsValid()) {
      setShowError(true)
    }
  }, [areAnnotationsValid, showValidationErrors])

  return (
    <div className="tw-relative tw-mb-4 tw-max-h-80 tw-overflow-auto">
      {isFeatureFlagEnabled('request_wysiwyg') ? (
        <WYSIWYGTextarea ref={textAreaRef} placeholder="Add Annotation here" onChange={onChange} />
      ) : (
        <Textarea
          className="tw-mt-2"
          value={currentEditAnnotationBodyText}
          error={showError}
          onChange={onTextAreaChange}
          placeholder="Add Annotation here"
          ref={textAreaRef}
          onKeyDown={onKeyDown}
          onClick={(e) => e.stopPropagation()}
        />
      )}
      <div className="tw-absolute tw-bottom-4 tw-right-4 tw-flex tw-w-24 tw-items-center tw-justify-end tw-gap-2 tw-pt-2">
        <IconButton color="secondary" className="tw-ml-2" onClick={onCancelClick} dataTestid="cancel-button">
          <X className="lu-light lu-md" />
        </IconButton>
        <IconButton color="primary" className="tw-ml-2" onClick={onSendClick} dataTestid="send-button">
          <Send className="lu-light lu-md" />
        </IconButton>
      </div>
    </div>
  )
}
