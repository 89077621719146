import { ReactElement, useEffect, useRef, useState } from 'react'

import TicketError from 'components/elements/ticket-error'
import { DesignRequest } from 'lib/api/tickets/tickets'

import FeatureFlagsProvider from 'lib/components/feature-flags/feature-flags-provider'
import { moveIntercom } from 'lib/util/intercom'
import AnnotationsListContainer from '../request/annotations/annotations-list-container'
import MediaContainer from '../request/media/media-container'
import MediaProvider, { useMediaContext } from '../request/media/media-provider'
import AnnotationsProvider from '../request/providers/annotations-provider'
import { AnimatedLoadingScreen } from '../requests/empty-screens'
import CollaborationUnavailable from './collaboration-unavailable'
import CollaboratorTicketProvider, { useCollaboratorTicketContext } from './collaborator-ticket-provider'
import CollaboratorTicketHeader from './header/collaborator-ticket-header'
import LeftSidebar from './left-sidebar'
import QueryClientProvider from 'providers/query-client-provider'

import {
  ResizableHandle,
  ResizablePanelGroup,
  ThreeResizablePanel,
  ThreeResizableProvider,
  useThreeResizableContext,
} from 'lib/components/resizable/resizable'
import { cn } from 'lib/util/cn'
import IconButton from 'lib/components/buttons/icon-button'
import { ClipboardList } from 'lucide-react'

const classNames = {
  container: 'tw-h-screen tw-w-screen tw-grid tw-grid-cols-1 tw-bg-white',
  main: 'tw-flex tw-flex-row tw-overflow-hidden tw-border-0 tw-border-t tw-border-solid tw-border-gray-200',
}

function CollaboratorTicketContent() {
  const [showMainContent, setShowMainContent] = useState(false)
  const [showMainOverflow, setShowMainOverflow] = useState(false)
  const { selectedFile } = useMediaContext()
  const timeoutRef = useRef<number>(null)

  function close() {
    setShowMainContent(false)
    setShowMainOverflow(false)
  }

  function open() {
    setShowMainContent(true)
    timeoutRef.current = window.setTimeout(() => {
      setShowMainOverflow(true)
    }, 750)
  }

  useEffect(() => {
    moveIntercom()
    return () => clearTimeout(timeoutRef.current)
  }, [])

  useEffect(() => {
    if (selectedFile) {
      open()
    } else {
      close()
    }
  }, [selectedFile])

  return (
    <ThreeResizableProvider>
      <ResizablePanelGroup direction="horizontal" autoSaveId="ticket-collab">
        <ThreeResizablePanel
          position="left"
          id="media-left-sidebar"
          order={0}
          minSize={20}
          collapsible
          className="tw-relative tw-min-w-16"
        >
          <LeftSidebar />
        </ThreeResizablePanel>
        <ResizableHandle />

        {showMainContent && (
          <>
            <ThreeResizablePanel
              position="middle"
              id="media-container"
              collapsible
              minSize={20}
              order={1}
              xButton={false}
              conditionalRender
            >
              <MediaContainer show={showMainContent} showOverflow={showMainOverflow} isColab />
            </ThreeResizablePanel>
            <ResizableHandle />
          </>
        )}

        <ThreeResizablePanel
          position="right"
          className="tw-relative tw-min-w-16"
          id="right-panel"
          order={2}
          minSize={30}
          collapsible
        >
          <RightPanel />
        </ThreeResizablePanel>
      </ResizablePanelGroup>
    </ThreeResizableProvider>
  )
}

const RightPanel = () => {
  const { rightCollapsed, toggleRightCollapsed } = useThreeResizableContext()
  return (
    <div className={cn('tw-relative tw-flex tw-h-full', rightCollapsed && 'tw-overflow-hidden')}>
      <div className="tw-fixed tw-flex tw-h-full tw-flex-col tw-gap-2 tw-border-0 tw-border-r tw-border-solid tw-border-gray-200 tw-p-3">
        <IconButton
          color={rightCollapsed ? 'secondary' : 'primary'}
          invert={!rightCollapsed}
          selected={!rightCollapsed}
          onClick={toggleRightCollapsed}
          dataTestid="timeline-button"
        >
          <ClipboardList className="lu-light lu-md" />
        </IconButton>
      </div>

      <div className="tw-w-full tw-px-5 tw-pl-20">
        <div className="tw-w-full tw-overflow-y-auto tw-bg-white tw-px-5 tw-pt-6">
          <AnnotationsListContainer />
        </div>
      </div>
    </div>
  )
}

function CollaboratorMainContentWrapper() {
  const { fetchAndSetTicket, ticket, ticketError, editMode } = useCollaboratorTicketContext()
  if (ticketError?.response?.data?.message?.toLowerCase().includes('expired')) {
    return <CollaborationUnavailable />
  }
  if (ticketError) {
    return (
      <div className="tw-flex tw-h-screen tw-items-center tw-justify-center">
        <TicketError />
      </div>
    )
  } else if (!ticket) {
    return (
      <div className="tw-flex tw-h-screen tw-items-center tw-justify-center">
        <AnimatedLoadingScreen />
      </div>
    )
  }

  return (
    <main className={classNames.main}>
      {ticket && (
        <MediaProvider
          fetchAndSetTicket={fetchAndSetTicket}
          isCollaboratorView
          ticket={ticket as DesignRequest}
          editMode={editMode}
          fromSources={['local_file_system']}
          useToken
          showRevisions
        >
          <AnnotationsProvider isCollaboratorView>
            <CollaboratorTicketContent />
          </AnnotationsProvider>
        </MediaProvider>
      )}
    </main>
  )
}

export default function CollaboratorTicket(): ReactElement {
  return (
    <FeatureFlagsProvider>
      <QueryClientProvider>
        <CollaboratorTicketProvider>
          <div className={classNames.container} style={{ gridTemplateRows: 'min-content auto' }}>
            <CollaboratorTicketHeader />
            <CollaboratorMainContentWrapper />
          </div>
        </CollaboratorTicketProvider>
      </QueryClientProvider>
    </FeatureFlagsProvider>
  )
}
