import { forwardRef, MutableRefObject, ReactElement, ReactNode, useEffect, useImperativeHandle, useRef } from 'react'
import { EditorContent, EditorContextValue, Extensions, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import { Underline } from '@tiptap/extension-underline'
import { TextAlign } from '@tiptap/extension-text-align'
import { Placeholder } from '@tiptap/extension-placeholder'
import debounce from 'lib/util/debounce'
import Video from './extensions/tiptap-video'

import './wysiwyg.scss'
import WYSIWYGToolbar from './wysiwyg-toolbar'
import { ImageResize } from 'tiptap-extension-resize-image'
import { EditorProps } from '@tiptap/pm/view'
import WYSIWYGFileModal from './wysiwyg-file-modal'
import { FilestackFile } from 'lib/util/filestack'
import Image from '@tiptap/extension-image'

const baseExtensions = [
  StarterKit.configure({
    blockquote: {
      HTMLAttributes: {
        class: 'tw-border-l-2 tw-border-solid tw-border-0 tw-pl-1 tw-border-neutral-200',
      },
    },
  }),
  Link.configure({
    autolink: true,
    openOnClick: true,
  }),
  Underline,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Image,
  ImageResize.configure({
    inline: true,
    HTMLAttributes: {
      class: 'tw-mx-auto tw-my-4 tw-block tw-max-w-full',
    },
  }),
  Video.configure({ HTMLAttributes: { class: 'tw-h-64 tw-w-96', controls: true, 'data-testid': 'wysiwyg-video' } }),
]

const editorProps: EditorProps = {
  attributes: {
    class: 'tw-p-4 focus:tw-outline-none',
  },
}

interface WYSIWYGTextareaProps {
  defaultValue?: string
  onChange: (value: string) => void
  placeholder?: string
  extensions?: Extensions
  prependToolbar?: ReactNode
  appendToolbar?: ReactNode
  disabled?: boolean
  showCannedResponseButton?: boolean
  ref?: MutableRefObject<EditorContextValue>
}

export default forwardRef(function WYSIWYGTextarea(props: WYSIWYGTextareaProps, ref): ReactElement {
  const {
    defaultValue,
    onChange,
    placeholder,
    extensions = [],
    prependToolbar,
    appendToolbar,
    disabled,
    showCannedResponseButton,
  } = props
  const content = useRef(defaultValue)

  if (placeholder) {
    baseExtensions.push(Placeholder.configure({ placeholder }) as never)
  }

  const editor = useEditor({
    extensions: [...baseExtensions, ...extensions],
    content: content.current,
    editorProps,
  })

  const onFilesUpload = (files: FilestackFile[]) => {
    for (const file of files) {
      if (file.mimetype.startsWith('video/')) {
        editor.chain().focus().setVideo({ src: file.url }).run()
      } else if (file.mimetype.startsWith('image/')) {
        editor.chain().focus().setImage({ src: file.url }).run()
      } else {
        editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: file.url, target: '_blank' })
          .insertContent(file.filename)
          .run()
      }
    }
  }

  useEffect(() => {
    if (editor) {
      const debouncedOnChange = debounce(() => onChange(editor.getHTML()), 300)
      editor.on('update', debouncedOnChange)
      return () => {
        editor.off('update', debouncedOnChange)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor])

  useImperativeHandle(ref, () => {
    return {
      editor,
    }
  })

  if (!editor) {
    return null
  }

  return (
    <div className="tw-w-full tw-flex-1 tw-rounded-md tw-border tw-border-solid tw-border-neutral-300 tw-bg-white">
      <WYSIWYGToolbar
        editor={editor}
        onFilesUploaded={onFilesUpload}
        prependChildren={prependToolbar}
        appendChildren={appendToolbar}
        showCannedResponseButton={showCannedResponseButton}
      />
      <EditorContent disabled={disabled} editor={editor} />
      <WYSIWYGFileModal editor={editor} onFilesUploaded={onFilesUpload} />
    </div>
  )
})
