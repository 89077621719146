import { ReactElement, useState, useCallback } from 'react'
import { useRequestContext } from '../providers/request-provider'
import HeaderActions from './header-actions'
import RequestName from './request-name'
import { TicketStatusBadge } from 'lib/components/badge/badge'
import HeaderDropdowns from './header-dropdowns'
import Breadcrumb from 'lib/components/breadcrumb/breadcrumb'
import HeaderRequestDetails from 'components/pages/request/request-header/header-request-details'
import CloseButton from '../media/close-button'
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react'

import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import ProjectsPopover from 'components/elements/projects/projects-popover'
import { Project } from 'interfaces/project'
import { updateTicket } from 'lib/api/tickets/tickets'
import { toast } from 'lib/components/toast/toast'

function RequestDetails(): ReactElement {
  const { ticket, invalidFields, showValidationErrors } = useRequestContext()
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()
  const { fetchAndSetTicket } = useRequestContext()
  const [isProjectsPopoverOpen, setIsProjectsPopoverOpen] = useState(false)

  const updateTicketProject = useCallback(
    async (project: Project | null) => {
      try {
        await updateTicket(Number(ticket?.id), { project_id: project?.id || null })
        fetchAndSetTicket()
        setIsProjectsPopoverOpen(false)
        toast.success('Ticket project updated')
      } catch (error) {
        toast.error('Failed to update ticket project')
        console.error('Failed to update ticket project', error)
      }
    },
    [fetchAndSetTicket, ticket?.id],
  )

  return (
    <div className="tw-w-full">
      <div className="tw-mb-2 tw-flex tw-flex-wrap tw-items-center tw-gap-4">
        {isFeatureFlagEnabled('projects') && (
          <ProjectsPopover
            isOpen={isProjectsPopoverOpen}
            onSelectProject={updateTicketProject}
            project={ticket?.project}
            setIsOpen={setIsProjectsPopoverOpen}
            style="dropdown"
          />
        )}
        <div className="tw-flex tw-items-center tw-gap-2">
          <RequestName showValidationError={invalidFields?.includes('subject') && showValidationErrors} />
          <TicketStatusBadge status={ticket?.status} className="tw-mx-0" />
        </div>
      </div>
    </div>
  )
}

export default function RequestHeader(): JSX.Element {
  const [isOpen, setIsOpen] = useState(true)
  const { showRevisions } = useRequestContext()

  function toggleOpen() {
    setIsOpen(!isOpen)
  }

  return (
    <header className="tw-relative">
      <>
        <Breadcrumb className="tw-ml-6 tw-mt-3" text="Back to all requests" href={`/requests`} />
        <div className="tw-flex tw-w-full tw-items-start tw-justify-between tw-px-3 tw-pb-3 tw-pt-4">
          <div className="tw-w-4/6">
            <RequestDetails />
          </div>
          <div className="tw-text-right">
            <HeaderActions />
          </div>
        </div>
        {isOpen && (
          <div>
            {!showRevisions && <HeaderDropdowns />}
            {showRevisions && <HeaderRequestDetails />}
          </div>
        )}
        <CloseButton Icon={isOpen ? ChevronUpIcon : ChevronDownIcon} onClick={toggleOpen} position="bottom" />
      </>
    </header>
  )
}
