import { ChangeEvent, KeyboardEvent, ReactElement, useMemo, useState } from 'react'
import { DetailTaskType } from '../types/detail-task'
import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import { BetaBadge } from 'lib/components/badge/badge'
import DirectionModal from '../directions/modals/direction-modal'
import { useDirectionsContext } from '../providers/timeline-directions-provider'
import TextInput from 'lib/components/text-input/text-input'
import { TextCounter } from 'lib/components/textarea/textarea'
import IconButton from 'lib/components/buttons/icon-button'
import { Check } from 'lucide-react'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import { useAnnotationsContext } from 'components/pages/request/providers/annotations-provider'
import WYSIWYGTextarea from 'lib/components/wysiwyg/wysiwyg-textarea'

const MINIMUM_LENGTH = 3

const PLACEHOLDER = 'Start typing general direction'

function DirectionsFlyoutMenu(): ReactElement {
  const [taskType, setTaskType] = useState<DetailTaskType>(null)
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()
  const showWYSIWYG = isFeatureFlagEnabled('request_wysiwyg')

  function clearTaskType() {
    setTaskType(null)
  }

  function menuItemClicked(type: DetailTaskType) {
    setTaskType(type)
  }

  return (
    <>
      <IconFlyoutMenu icon={['far', showWYSIWYG ? 'magic' : 'plus']} color="primary" size="lg" invert active>
        <IconFlyoutMenu.Button onClick={() => menuItemClicked(DetailTaskType.GEN_AI_REQUEST)}>
          Help me write my design request <BetaBadge />
        </IconFlyoutMenu.Button>
        <IconFlyoutMenu.Button onClick={() => menuItemClicked(DetailTaskType.TXT2IMG)}>
          Help me create imagery <BetaBadge />
        </IconFlyoutMenu.Button>
        {!showWYSIWYG && (
          <IconFlyoutMenu.Button onClick={() => menuItemClicked(DetailTaskType.COPY)}>
            Add my own copy
          </IconFlyoutMenu.Button>
        )}
      </IconFlyoutMenu>
      <DirectionModal onClose={clearTaskType} type={taskType} />
    </>
  )
}

function hasMinimumLength(s: string) {
  return s.trim().length >= MINIMUM_LENGTH
}

export default function TimelineDirectionForm(): ReactElement {
  const [showAttemptedSubmitError, setShowAttemptedSubmitError] = useState<boolean>(false)
  const [value, setValue] = useState<string>('')
  const { addTask, directions, setNewDirectionHasValue, showDirectionErrors } = useDirectionsContext()
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()
  const { annotationsInEditMode, areAnnotationsValid } = useAnnotationsContext()
  const [renderKey, setRenderKey] = useState(1)
  const showWYSIWYG = isFeatureFlagEnabled('request_wysiwyg')

  const showError = useMemo(() => {
    if (isFeatureFlagEnabled('merging')) {
      return showDirectionErrors && !areAnnotationsValid() && annotationsInEditMode === 0
    }
    if (directions?.length === 0) {
      return showDirectionErrors || showAttemptedSubmitError
    }

    return (showDirectionErrors && value.trim().length !== 0) || (showAttemptedSubmitError && !hasMinimumLength(value))
  }, [
    annotationsInEditMode,
    areAnnotationsValid,
    directions?.length,
    isFeatureFlagEnabled,
    showAttemptedSubmitError,
    showDirectionErrors,
    value,
  ])

  function clear() {
    setRenderKey((prev) => prev + 1)
  }

  function onTextAreaChange(e: ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.value
    onChange(newValue)
  }

  function onChange(newValue: string) {
    setNewDirectionHasValue(!!newValue)
    setValue(newValue)

    if (newValue.trim().length === 0 || hasMinimumLength(newValue)) {
      setShowAttemptedSubmitError(false)
    }
  }

  async function onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      save()
    }
  }

  function save() {
    if (!hasMinimumLength(value)) {
      setShowAttemptedSubmitError(true)
      return
    }

    setShowAttemptedSubmitError(false)
    addTask(value.trim())
    clear()
  }

  return (
    <>
      <div className="tw-flex tw-w-full tw-items-start tw-gap-2">
        {!isFeatureFlagEnabled('request_wysiwyg') && (
          <div className="tw-mt-3">
            <DirectionsFlyoutMenu />
          </div>
        )}
        <div className="tw-w-full">
          <div className="tw-mb-4 tw-max-h-80 tw-overflow-auto">
            {showWYSIWYG ? (
              <WYSIWYGTextarea
                key={renderKey}
                placeholder={PLACEHOLDER}
                prependToolbar={<DirectionsFlyoutMenu />}
                onChange={onChange}
              />
            ) : (
              <TextInput
                error={showError}
                maxLength={500}
                onChange={onTextAreaChange}
                onKeyDown={onKeyDown}
                placeholder={PLACEHOLDER}
                value={value}
              />
            )}
            <div className="tw-absolute tw-bottom-12 tw-right-4 tw-flex tw-min-w-10 tw-items-center tw-justify-end tw-gap-2 tw-pt-2">
              <IconButton color="secondary" onClick={save}>
                <Check className="lu-sm" />
              </IconButton>
            </div>
          </div>
          {!showWYSIWYG && <TextCounter value={value} className="tw-mr-12 tw-text-right" />}
        </div>
      </div>
      {!showWYSIWYG && <p className="tw--mt-8 tw-pt-2.5">Press enter for another line</p>}
    </>
  )
}
