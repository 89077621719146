import { ChangeEvent, ReactElement, useEffect, useRef, useState, MouseEvent } from 'react'

import { Annotation, AnnotationRecord } from 'lib/api/annotations/annotations'
import { AnnotoriousAnnotation } from 'lib/components/annotation/annotorious-openseadragon-types'
import IconButton from 'lib/components/buttons/icon-button'
import Textarea from 'lib/components/textarea/textarea'

import { useAnnotationsContext } from '../providers/annotations-provider'
import { DetailTaskType } from '../types/detail-task'
import { toast } from 'lib/components/toast/toast'
import { useRequestContext } from 'components/pages/request/providers/request-provider'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import WYSIWYGTextarea from 'lib/components/wysiwyg/wysiwyg-textarea'

interface TimelineAnnotationEditProps {
  annotation: Annotation
  disableEditMode: () => void
}

export default function TimelineAnnotationEdit({
  annotation,
  disableEditMode,
}: TimelineAnnotationEditProps): ReactElement {
  const [value, setValue] = useState(annotation.body || '')
  const textareaRef = useRef(null)
  const { showValidationErrors } = useRequestContext()
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()
  const {
    areAnnotationsValid,
    highlightedAnnotation,
    saveAnnotation,
    selectAnnotation,
    setHighlightedAnnotation,
    updateVideoAnnotation,
  } = useAnnotationsContext()

  function hasMinimumLength() {
    return value.trim().length >= 3
  }

  async function save() {
    if (value.trim() === annotation.body) {
      disableEditMode()
    } else if (hasMinimumLength()) {
      try {
        if (annotation.type === DetailTaskType.VIDEO_ANNOTATION) {
          if ('comments' in annotation.data) {
            annotation.data.comments[0].body = value
          }
          updateVideoAnnotation(annotation.id, annotation.uuid, value)
        } else {
          await saveAnnotation(annotation as AnnotationRecord<AnnotoriousAnnotation>, value)
        }
        disableEditMode()
      } catch (error) {
        console.error('TimelineAnnotationEdit Error:', error)
        toast.error('There was an error updating the annotation. Refresh and try again.')
      }
    }
  }

  function onTextAreaChange(e: ChangeEvent<HTMLTextAreaElement>) {
    const newValue = e.target.value
    onChange(newValue)
  }

  function onChange(newValue: string) {
    setValue(newValue)
  }

  useEffect(() => {
    if (textareaRef.current) {
      if (isFeatureFlagEnabled('request_wysiwyg')) {
        textareaRef.current.editor.commands.focus()
      } else {
        textareaRef.current.focus()
        textareaRef.current.scrollTop = textareaRef.current.scrollHeight
        textareaRef.current.setSelectionRange(textareaRef.current.value.length, textareaRef.current.value.length)
      }
    }
    selectAnnotation(annotation as AnnotationRecord<AnnotoriousAnnotation>)
    setHighlightedAnnotation(annotation as AnnotationRecord<AnnotoriousAnnotation>)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFeatureFlagEnabled])

  function handleClick(e: MouseEvent) {
    e.stopPropagation()

    if (highlightedAnnotation?.uuid !== annotation?.uuid && highlightedAnnotation?.id !== annotation?.id) {
      setHighlightedAnnotation(annotation as AnnotationRecord<AnnotoriousAnnotation>)
      selectAnnotation(annotation as AnnotationRecord<AnnotoriousAnnotation>)
    }
  }

  return (
    <div className="tw-relative tw-flex tw-w-full tw-items-center tw-gap-2">
      {isFeatureFlagEnabled('request_wysiwyg') ? (
        <WYSIWYGTextarea
          ref={textareaRef}
          defaultValue={annotation.body}
          onChange={onChange}
          placeholder="Edit Annotation here"
        />
      ) : (
        <Textarea
          value={value}
          onChange={onTextAreaChange}
          placeholder="Edit Annotation here"
          error={!hasMinimumLength() || (showValidationErrors && !areAnnotationsValid())}
          onClick={handleClick}
          ref={textareaRef}
        />
      )}
      <div className="tw-absolute tw-bottom-4 tw-right-4 tw-flex tw-min-w-10 tw-items-center tw-justify-end tw-gap-2 tw-pt-2">
        <IconButton color="secondary" icon={['far', 'check']} onClick={save} />
        <span onMouseDown={(e) => e.preventDefault()}>
          <IconButton color="secondary" icon={['far', 'times']} onClick={disableEditMode} />
        </span>
      </div>
    </div>
  )
}
