import { ReactElement } from 'react'

import { AppSettings } from 'interfaces/app'
import { AdminUser } from 'providers/admin-user-provider'

import AdminApp from 'components/pages/admin/admin-app/admin-app'
import AdminHeader from './header/admin-header'
import AdminRightPanel from './right-panel/admin-right-panel'
import AdminMediaSidebar from './left-panel/admin-media-sidebar'
import AdminMediaContainer from './center-content/admin-media-container'
import AdminTicketProvider from './providers/admin-ticket-provider'
import AdminMediaProvider, { useAdminMediaContext } from './providers/admin-media-provider'
import AdminTimelineProvider from './providers/admin-timeline-provider'
import AdminAnnotationHighlightProvider from './providers/admin-annotation-highlight-provider'
import { Toaster } from 'lib/components/toast/toast'
import FeatureFlagsProvider from 'lib/components/feature-flags/feature-flags-provider'
import { ResizableHandle, ThreeResizablePanel, ThreeResizableProvider } from 'lib/components/resizable/resizable'
import { ResizablePanelGroup } from 'lib/components/resizable/resizable'

interface AdminTicketViewProps {
  settings: AppSettings
  user: AdminUser
}

const classNames = {
  container:
    'tw-h-[calc(100vh-var(--header-height,0px))] tw-mt-[var(--header-height,0px)] tw-w-screen tw-grid tw-grid-cols-1 tw-bg-white',
  main: 'tw-flex tw-flex-row tw-overflow-hidden tw-border-0 tw-border-t tw-border-solid tw-border-gray-200',
}

function AdminTicketContent(): ReactElement {
  const { selectedFile } = useAdminMediaContext()

  return (
    <div className={classNames.container} style={{ gridTemplateRows: 'min-content auto' }}>
      <AdminHeader />
      <main className={classNames.main}>
        <ThreeResizableProvider>
          <ResizablePanelGroup direction="horizontal" autoSaveId="admin-ticket">
            <ThreeResizablePanel
              position="left"
              id="media-sidebar"
              collapsible
              minSize={20}
              order={1}
              className="tw-min-w-16"
              buttonClassName="tw-top-0.5 tw-right-3"
            >
              <AdminMediaSidebar />
            </ThreeResizablePanel>
            <ResizableHandle />
            {selectedFile && (
              <>
                <ThreeResizablePanel
                  position="middle"
                  id="media-container"
                  collapsible
                  minSize={20}
                  order={2}
                  xButton={false}
                  conditionalRender
                >
                  <AdminMediaContainer />
                </ThreeResizablePanel>
                <ResizableHandle />
              </>
            )}
            <ThreeResizablePanel
              className="tw-min-w-16"
              position="right"
              id="right-panel"
              collapsible
              minSize={20}
              order={3}
            >
              <AdminRightPanel />
            </ThreeResizablePanel>
          </ResizablePanelGroup>
        </ThreeResizableProvider>
      </main>
    </div>
  )
}

export default function AdminTicketView({ settings, user }: AdminTicketViewProps): ReactElement {
  return (
    <FeatureFlagsProvider>
      <AdminApp settings={settings} user={user}>
        <Toaster />
        <AdminTicketProvider>
          <AdminMediaProvider>
            <AdminTimelineProvider>
              <AdminAnnotationHighlightProvider>
                <AdminTicketContent />
              </AdminAnnotationHighlightProvider>
            </AdminTimelineProvider>
          </AdminMediaProvider>
        </AdminTicketProvider>
      </AdminApp>
    </FeatureFlagsProvider>
  )
}
